import logo from './logo.svg';
import './App.css';
import {Navbar, Nav, Container, Button} from 'react-bootstrap';

function App() {
  return (
    <div className="App">
      <Navbar bg="light" variant="light">
        <Container>
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#projects">Projects</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <div className="container">
      <div className="my-5"></div>
      <div className="display-3" style={{ color: "#FF8A00" }}>
        <span style = {{color : "white"}}>Hello, I'm</span> Ankit.
      </div>
      <div className="text-muted mx-5 my-4 h3 text-justify info">
        <p>
          Software Developer by passion and profession. <br/>
          I have worked across domains such as <strong>Backend Development</strong> and <strong>Distributed Systems</strong>
        </p>
      </div>
      <div class = "footer">
        <small className="text-muted ml-2 text-justify info">&copy; Ankit Agarwal, 2022. All rights reserved.</small>
      </div>
    </div>
    </div>
  );
}

export default App;
